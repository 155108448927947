body {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
    background: #020202;
    overflow-x: hidden;
}

.page {
    background: radial-gradient(50% 50% at 50% 50%, #131313 1%, #020202 100%);
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.login-form {
    justify-content: center;
    align-items: center;
    display: inline-flex;

    gap: 220px;
    .logo {
        width: 430.211px;
        gap: 100px;
        height: 112px;
        .logo-text {
            display: inline-block;
            color: #d2e214;
            font-size: 74.523px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            .logo-white-text {
                color: #fff;
                font-size: 74.523px;
                font-weight: 300;
            }
        }
    }
    .form {
        display: flex;
        width: 611px;
        height: 588px;
        padding: 0px 35px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 30px;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(5px);
        .form-header {
            color: #fff;
            font-family: Poppins;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .form-field-error {
            color: #ef3737;
        }
        .form-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 50px;
            .form-fields {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 29px;
                .form-field {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;

                    .form-field-header {
                        color: #fff;
                        font-family: Poppins;
                        font-size: 16.033px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    .form-field-input {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                        .form-field-input-box {
                            width: 544px;
                            height: 58px;
                            border-radius: 8px;
                            border: 1px solid rgba(255, 255, 255, 0.1);
                            background: rgba(255, 255, 255, 0.04);
                            backdrop-filter: blur(5px);
                            .form-field-input-label {
                                display: flex;
                                align-items: center;
                                gap: 12px;
                                position: absolute;
                                left: 17.395px;
                                top: 17px;
                                .form-field-input-label-svg {
                                    width: 24px;
                                    height: 24px;
                                }
                                .form-field-input-label-text {
                                    color: #7f7f7f;
                                    font-family: Poppins;
                                    font-size: 16.033px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                                input {
                                    width: 100%;
                                    height: 100%;
                                    border: none;
                                    background: transparent;
                                    color: #fff;
                                    font-family: Poppins;
                                    font-size: 16.033px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    &:focus {
                                        outline: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .button {
        display: flex;
        width: 180px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: #d2e214;
        height: 58px;
        color: #060607;
        font-family: Poppins;
        font-size: 16.033px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.background-image {
    position: fixed; /* or absolute */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.panel-header {
    width: 100vw;
    max-width: 100%;
    justify-content: center;
    align-items: center;
    gap: 25px;
    display: inline-flex;
    flex-direction: column;
    .background-image {
        position: absolute; /* Changed from fixed to absolute */
        top: 0;
        left: 0;
        width: 100%; /* Adjusted to 100% instead of 100vw */
        height: 100%; /* Adjusted to fill the parent div */
        object-fit: cover;
    }
    .nav-bar {
        padding-top: 50px;
        display: flex;
        gap: 100px;
        width: 100vw;
        flex-direction: row;
        justify-content: center;
        align-items: center !important;
        .nav-bar-logo {
            display: flex;
            flex-direction: row;
            gap: 10px;
            flex-shrink: 0;
            .logo-icon {
                width: 21.617px;
                height: 19.215px;
                flex-shrink: 0;
                stroke-width: 1.801px;
                stroke: #d2e214;
                line-height: 60px;
            }
            .logo-text {
                display: inline-block;

                color: #d2e214;
                font-family: Poppins;
                font-size: 28.722px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                .logo-white-text {
                    color: #fff;
                    font-family: Poppins;
                    font-size: 28.722px;
                    font-weight: 300;
                }
            }
        }
        .nav-bar-nav {
            display: flex;
            flex-direction: row;
            padding: 10px;
            justify-content: center;
            align-items: center;
            gap: 8.22px;
            border-radius: 8.22px;
            border: 0.822px solid rgba(255, 255, 255, 0.1);
            background: rgba(255, 255, 255, 0.04);
            backdrop-filter: blur(4.1101579666137695px);
            .tab {
                display: flex;
                padding: 8.22px;
                flex-direction: column;
                align-items: flex-start;
                gap: 8.22px;
                border: solid rgba(255, 255, 255, 0);
                text-decoration: none;

                .tab-interior {
                    display: flex;
                    align-items: center !important;
                    gap: 6.576px;
                    .tab-icon {
                        width: 14.797px;
                        height: 14.797px;
                        line-height: 14px;
                        stroke: #7f7f7f;
                        stroke-width: 0.5px;
                    }
                    .tab-text {
                        color: #7f7f7f;
                        font-family: Poppins;
                        font-size: 13.179px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }
            }
            .tab:hover {
                cursor: pointer;
            }
            .tab:hover,
            .tab.active {
                background: rgba(255, 255, 255, 0.1);
                border-radius: 5.754px;
                border: solid rgba(255, 255, 255, 0.1);
                background: rgba(255, 255, 255, 0.06);
                backdrop-filter: blur(4.1101579666137695px);
                .tab-icon {
                    stroke: #d2e214;
                }
            }
        }
        .nav-bar-user {
            display: inline-flex;
            padding-left: 10px;
            justify-content: flex-end;
            align-items: center !important;
            gap: 13.345px;
            border-radius: 5.87px;
            backdrop-filter: blur(5px);
            .nav-bar-user-info {
                display: flex;
                align-items: center !important;
                gap: 8px;
                .nav-bar-user-info-icon {
                    width: 20.405px;
                    height: 20.405px;
                    border-radius: 20.405px;
                }
                .nav-bar-user-info-name {
                    color: #fff;
                    font-family: Poppins;
                    font-size: 13.682px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                .nav-bar-user-split {
                    width: 1px;

                    height: 39px;
                    background: #201f1f;
                }
                .nav-bar-user-logout {
                    width: 17.5px;
                    height: 17.5px;
                }
                .nav-bar-user-logout:hover {
                    cursor: pointer;
                }
            }
        }
    }
    .live-feed {
        display: flex;
        flex-direction: column !important;
        justify-content: center;
        align-items: center !important;
        width: 100vw;
        flex-shrink: 0;
        .live-feed-header {
            width: 150px;
            flex-direction: row;
            justify-content: center;
            align-items: center !important;
            gap: 10px;
            display: flex;
            height: 24px;
            flex-shrink: 0;
            .live-feed-icon {
                width: 20.75px;
                height: 20.75px;
                flex-shrink: 0;
            }
            .live-feed-text {
                color: #fff;
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .live-feed-toggle {
                width: 10.64px;
                height: 6.175px;
                line-height: 0px;
                flex-shrink: 0;
                fill: #7f7f7f;
                rotate: 0deg;
            }
            .live-feed-toggle:hover {
                cursor: pointer;
            }
            .live-feed-toggle.active {
                rotate: 180deg;
            }
        }
        .live-feed-content {
            padding-top: 20px;
            display: flex;
            width: 1264px;
            align-items: flex-start;
            gap: 9.003px;
            overflow-x: hidden;
            flex-shrink: 0;
            .live-feed-item {
                width: 118.29px;
                height: 147.373px;
                border-radius: 10px;
                border-color: #47465a;
                border-style: solid;
                border-width: 1px;
                background: rgba(255, 255, 255, 0.04);
                flex-shrink: 0;
                display: flex;
                flex-direction: column !important;
                justify-content: center;
                align-items: center !important;
                .live-feed-item-background {
                    width: 118.29px;
                    height: 147.373px;
                    flex-shrink: 0;
                }
                .live-feed-item-image img {
                    width: 66.997px;
                }
                .live-feed-item-price {
                    color: #fff;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 15.005px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                .live-feed-item-type {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 2.401px;
                    .live-feed-item-type-icon {
                        display: flex;
                        padding-bottom: 1.2px;
                        align-items: center;
                        gap: 12.004px;
                        fill: #d2e214;
                    }
                    .live-feed-item-type-text {
                        color: #7f7f7f;
                        text-align: center;
                        font-family: Poppins;
                        font-size: 12.004px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
    }
    .nav-bar-line {
        width: 100vw;
        height: 1px;
        background: rgba(255, 255, 255, 0.1);
    }
}

.panel-content {
    padding-top: 25px;
    position: relative;
    flex-direction: column;
    align-items: center;
    background: radial-gradient(50% 50% at 50% 50%, #131313 1%, #020202 100%);
    color: #fff;
    .panel-wrapper {
        max-width: 1400px;
        margin: auto;
        display: flex;
        flex-direction: column !important;
        gap: 50px;
        .panel-section {
            display: flex;
            flex-direction: column !important;
            gap: 25px;
            .panel-section-header {
                align-items: left;
                color: #fff;
                font-family: Poppins;
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .panel-section-selection {
                display: flex;
                align-items: row;
                gap: 38px;
                color: #7f7f7f;
                text-align: center;
                font-family: Poppins;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                .panel-section-selection-left {
                    display: flex;
                    flex-direction: flex-start;
                    gap: 10px;
                }
                .panel-section-selection-right {
                    display: flex;
                    gap: 38px;
                    justify-content: flex-end;
                    flex: 1; /* Add this line */
                }
                .panel-section-selection-header {
                    line-height: 39px;
                }
                .panel-section-selection-header-large {
                    align-items: left;
                    color: #fff;
                    font-family: Poppins;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                .panel-section-selection-number {
                    color: #fff;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                .panel-section-group {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                }
            }
        }
    }
}

.panel-section-button {
    display: flex;
    padding: 8px 14px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.06);
    color: #7f7f7f;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}
.panel-section-button:hover {
    cursor: pointer;
}
.panel-section-button:hover,
.panel-section-button.active {
    border-radius: 6px;
    background: var(--Yellow, #d2e214);
    color: #010101;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.chart {
    display: inline-flex;
    height: 680px;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    .chart-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        animation: spin 1s linear infinite;
        width: 15px;
        height: 15px;
        margin: auto; /* Add this line */
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.stat-row {
    display: flex;
    justify-content: center;
    gap: 11px;
    .stat-box {
        display: flex;
        width: 337px;
        height: 106px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-radius: 10px;
        gap: 20px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(255, 255, 255, 0.04);
        backdrop-filter: blur(5px);
        .stat-box-loading {
            display: flex;
            justify-content: center;
            align-items: center;
            animation: spin 1s linear infinite;
            width: 15px;
            height: 15px;
            margin-right: 29px;
            margin: auto; /* Add this line */
        }
        .stat-box-inner {
            padding-left: 29px;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            .stat-box-icon {
                width: 45.084px;
                height: 45.084px;
            }
            .stat-box-info {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: -3px;
                .stat-box-number {
                    color: #fff;
                    font-family: Poppins;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                .stat-box-description {
                    color: #7f7f7f;
                    font-family: Poppins;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

.chart-stats {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 5px;
    .chart-stat {
        display: flex;
        padding: 10px;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        gap: 10px;

        .chart-stat-button {
            display: flex;
            line-height: 10px;
            padding: 4.5px;
            justify-content: center;
            align-items: center;

            .chart-stat-inner {
                border-radius: 1.2px;
                background: #151515;
                backdrop-filter: blur(7.500000476837158px);
                width: 14px;
                height: 14px;
                flex-shrink: 0;
                .chart-stat-inner:hover {
                    cursor: pointer;
                }
            }
        }

        .chart-stat-text {
            color: #fff;
            text-align: center;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.site-bots-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 200px;
    gap: 18px;
    width: 1400px;
    flex-wrap: wrap;
    .site-bot {
        display: flex;
        align-items: flex-start;
        gap: 18px;
        .site-bot-loading {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            width: 400px;
            height: 241px;
            .site-bot-loading-inner {
                display: flex;
                justify-content: center;
                align-items: center;
                animation: spin 1s linear infinite;
                width: 15px;
                height: 15px;
                margin-right: 29px;
                margin: auto; /* Add this line */
            }
        }
        .site-bot-inner {
            display: flex;
            padding: 18px;
            justify-content: flex-end;
            align-items: center;
            border-radius: 10px;
            border: 1px solid rgba(255, 255, 255, 0.1);
            background: rgba(255, 255, 255, 0.04);
            backdrop-filter: blur(5px);
            .site-bot-content {
                display: flex;
                width: 400px;
                flex-direction: column;
                align-items: flex-start;
                gap: 15px;
                .site-bot-header {
                    display: flex;
                    width: 400px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 24px;
                    .site-bot-header-left {
                        display: flex;
                        align-items: center;
                        gap: 16px;
                        .site-bot-header-icon {
                            display: flex;
                            width: 35px;
                            height: 35px;
                            padding: 10px 14px;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            border-radius: 6px;
                            border: 1px solid rgba(255, 255, 255, 0.1);
                            background: rgba(255, 255, 255, 0.04);
                            backdrop-filter: blur(5px);
                        }
                        .site-bot-header-text-container {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: -5px;
                            .site-bot-header-text-title {
                                color: #fff;
                                font-family: Poppins;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                            .site-bot-header-text-subtitle {
                                color: #fff;
                                font-family: Poppins;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                            .healthy {
                                color: #26d07f;
                            }
                            .dead {
                                color: red;
                            }
                        }
                    }
                }
                .site-bot-divider {
                    width: 400px;
                    height: 0.857px;
                    flex-shrink: 0;
                    background: var(--Sw-Dark-Grey, #202023);
                }
                .site-bot-info {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 25px;
                    .site-bot-info-inner {
                        display: flex;
                        width: 400px;
                        align-items: flex-start;
                        gap: 10px;
                        .site-bot-info-inner-two {
                            display: flex;
                            width: 144px;
                            height: 57px;
                            padding: 10px px;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            border-radius: 6px;
                            border: 1px solid rgba(255, 255, 255, 0.1);
                            background: rgba(255, 255, 255, 0.04);
                            backdrop-filter: blur(5px);
                            .site-bot-info-inner-three {
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                font-size: 14px;
                                .site-bot-info-toggle-text {
                                    display: flex;
                                    align-items: center;
                                    gap: 11px;
                                }
                                .site-bot-info-toggle {
                                    width: 36px;
                                    height: 18px;
                                }
                                .site-bot-info-stats-icon {
                                    width: 15.4px;
                                    height: 15.4px;
                                    flex-shrink: 0;
                                }
                                .site-bot-info-stats-number {
                                    display: flex;
                                    align-items: center;
                                    gap: 11px;
                                }
                            }
                        }
                    }
                }
                .site-bot-accounts {
                    width: 375px;
                    height: 105px;
                    border-radius: 8.396px;
                    background: rgba(255, 255, 255, 0.04);
                    backdrop-filter: blur(10.495254516601562px);
                    display: flex;
                    padding: 15px;
                    flex-direction: column;
                    gap: 20px;
                    .site-bot-accounts-header {
                        display: flex;
                        flex-direction: row;
                        .site-bot-accounts-header-left {
                            display: flex;
                            padding: 1.143px 36px 0.857px 0px;
                            align-items: center;
                            gap: 16px;
                            .site-bot-accounts-header-text {
                                color: #fff;
                                font-family: Poppins;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
                            .site-bot-accounts-header-icon {
                                display: flex;
                                padding: 10px;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                border-radius: 6px;
                                border: 1px solid rgba(255, 255, 255, 0.1);
                                background: rgba(255, 255, 255, 0.04);
                                backdrop-filter: blur(5px);
                                .site-bot-accounts-header-icon-inner {
                                    display: flex;
                                    padding: 2px;
                                    align-items: flex-start;
                                    gap: 10px;
                                    svg {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }
                            }
                        }
                        .site-bot-accounts-header-right {
                            display: flex;
                            justify-content: flex-end;
                            gap: 15px;
                            flex: 1 1;
                            .site-bot-accounts-header-right-number {
                                display: flex;
                                width: 125px;
                                height: 30px;
                                padding: 5px 9px;
                                flex-direction: column;
                                justify-content: center;
                                text-align: center;

                                gap: 10px;
                                border-radius: 6px;
                                border: 1px solid rgba(255, 255, 255, 0.1);
                                background: rgba(255, 255, 255, 0.04);
                                backdrop-filter: blur(5px);
                                .site-bot-accounts-header-right-number-inner {
                                    color: #fff;
                                    text-align: center;
                                    font-family: Poppins;
                                    font-size: 16x;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 27.43px; /* 152.387% */
                                    letter-spacing: 0.18px;
                                }
                            }
                        }
                    }
                    .site-bot-accounts-body {
                        display: flex;
                        width: 400px;
                        align-items: flex-center;
                        gap: 13px;

                        .site-bot-accounts-stat {
                            display: flex;
                            width: 66px;
                            padding: 6px 9px;
                            justify-content: center;
                            align-items: flex-end;
                            gap: 10.5px;
                            flex-shrink: 0;
                            border-radius: 6px;
                            background: #0d0d0d;
                            .site-bot-accounts-stat-left {
                                color: #7f7f7f;
                                font-family: Poppins;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                                width: 50px;
                            }
                            .site-bot-accounts-stat-right {
                                color: #fff;
                                text-align: right;
                                font-family: Poppins;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 24px;
                                width: 50px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 4px;
    background-color: #0e0e0e;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #d2e214;
}

input:focus + .slider {
    box-shadow: 0 0 1px #d2e214;
}

input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.panel-row {
    display: flex;
    flex-direction: row;
    color: #7f7f7f;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .panel-row-left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 38px;
    }
    .panel-row-right {
        gap: 38px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        vertical-align: middle;
        flex: 1 1;
    }
    .panel-row-group {
        gap: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .panel-row-title {
        color: #fff;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .panel-row-bold-value {
        color: #fff;
        text-align: center;
        font-family: Poppins;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
    .panel-row-green-text {
        align-items: center;

        color: var(--Yellow, #d2e214);
        text-align: center;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .panel-row-red-background {
        border: 1px solid #ef3737;
        border-radius: 5px;
        background-color: rgba(239, 55, 55, 0.15);
        padding: 4px 8px 4px 8px;
        color: #ef3737;
        font-family: Poppins;
        font-size: 15.122px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        flex-direction: row;
    }
    .panel-row-green-background {
        border: 1px solid #0ecb43;
        border-radius: 5px;
        background-color: rgba(14, 203, 67, 0.15);
        padding: 4px 8px 4px 8px;
        color: #0ecb43;
        font-family: Poppins;
        font-size: 15.122px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        flex-direction: row;
    }
    .panel-row-slider {
        display: flex;
        align-items: center;
        gap: 11px;
        .panel-row-slider-inactive {
            color: #7f7f7f;
            text-align: center;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .panel-row-slider-active {
            color: #fff;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .panel-row-search {
        display: flex;
        align-items: flex-start;
        padding: 6px;
        gap: 7px;
        border-radius: 6px;
        border: 0.822px solid rgba(255, 255, 255, 0.1);
        background: rgba(255, 255, 255, 0.04);
        backdrop-filter: blur(4.1101579666137695px);
        .panel-row-search-input {
            color: #7f7f7f;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: transparent;
            border: none;
        }
        .panel-row-search-input:focus {
            outline: none;
        }
    }
    /* Styles the overall DatePicker container */
    .react-datepicker {
        border-radius: 6px;
        background: #121212;
        padding: 10px;
    }
    .react-datepicker-wrapper {
        input {
            border: none;
            height: 39px;
            width: 126px;
            border-radius: 6px;
            text-align: center;
            background: rgba(255, 255, 255, 0.06);
            color: #7f7f7f;
            text-align: center;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        input:focus {
            outline: none;
        }
    }

    /* Styles the header of the DatePicker */
    .react-datepicker__header {
        background: #121212;
        border-color: #020202;
        border-bottom: 1px;
        color: white !important;
    }

    /* Styles the selected day */
    .react-datepicker__day {
        color: #7f7f7f;
    }

    .react-datepicker__day--selected {
        background-color: #1a1a1a;
        color: white;
    }
    .react-datepicker__current-month {
        color: #fff;
    }
    .react-datepicker__day-name {
        color: #fff;
    }

    /* Styles the days on hover */
    .react-datepicker__day:hover {
        background-color: #d2e214;
        color: #fff;
    }

    /* Styles the navigation buttons (previous and next month) */
    .react-datepicker__navigation {
        top: 10px;
        width: 15px;
        height: 15px;
        background-size: 20px;
        border-radius: 50%;
        background-color: #7f7f7f;
    }

    /* Styles the month and year dropdowns */
    .react-datepicker__month-select,
    .react-datepicker__year-select {
        padding: 5px;
        margin: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
    select {
        display: none;
    }

    .panel-row-select {
        position: relative;
        display: inline-block;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 0.06);
        color: #7f7f7f;
        padding: 8px 4px;
        text-align: center;
        cursor: pointer;
        border: none;
        color: #7f7f7f;
        text-align: center;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
    }

    .panel-row-select:focus {
        outline: none;
    }

    .panel-row-select option {
        background-color: rgba(255, 255, 255, 0.06);
    }
}

@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

.table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    .table-header {
        display: flex;
        width: 100%;
        flex-direction: row;
        border-radius: 6px;
        background: #0b0b0b;
        height: 42px;
        flex-shrink: 0;
        align-items: center;
        color: #7f7f7f;
        font-family: Poppins;
        font-size: 12.5px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        padding-left: 10px;
        margin-left: -5px;
        .table-header-column {
            display: flex;
            flex-direction: row;
            gap: 3px;
        }
    }
    .table-body {
        width: 100%;
        gap: 5px;
        display: flex;
        flex-direction: column;
        .row {
            display: flex;
            flex-direction: row !important;
            padding-left: 10px;
            margin-left: -5px;
            height: 69px;
            align-items: center;
            width: 100%;
            border-radius: 6px;
            background: rgba(255, 255, 255, 0.04);
            color: #7f7f7f;
            font-family: Poppins;
            font-size: 12.5px;
            font-style: normal;
            font-weight: 400;
            line-height: 20.572px; /* 164.578% */
            .row-item {
                display: flex;
                flex-direction: row !important;
                width: 100%;
                align-items: center;
                gap: 6px;
            }
        }
        .row.loading {
            animation: shimmer 1.5s infinite linear;
            background: linear-gradient(to right, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.04) 100%);
            background-size: 1000px 100%;
        }
    }
    .green {
        color: var(--Yellow, #d2e214);
        font-family: Poppins;
        font-size: 12.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.572px;
    }
    .white {
        color: #fff;
        font-family: Poppins;
        font-size: 12.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 20.572px; /* 164.578% */
    }
    .table-footer {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .table-footer-input {
            width: 38px;
            height: 38px;
            border-radius: 6px;
            background: rgba(255, 255, 255, 0.06);
            border: none;
            color: #7f7f7f;
            text-align: center;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .table-footer-input:focus {
            outline: none;
        }
        .table-footer-page-selector {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            .table-footer-button {
                display: flex;
                padding: 8px 12px 8px 14px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 6px;
                background: rgba(255, 255, 255, 0.06);
                color: #7f7f7f;
                text-align: center;
                font-family: Poppins;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .table-footer-button-active {
                display: flex;
                padding: 8px 12px 8px 14px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 6px;
                background: var(--Yellow, #d2e214);
                color: #fff;
                text-align: center;
                font-family: Poppins;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .table-footer-button:hover {
                cursor: pointer;
            }
        }
    }
}

.site-icon {
    display: flex;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 3.373px;
    border: 0.897px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(4.487180233001709px);
    img {
        width: 25px;
        height: 25px;
        margin: auto;
        border-radius: 6px;
    }
}

.account-icon {
    width: 35px;
    height: 35px;
    border-radius: 3.373px;
    border: 0.897px solid rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(4.487180233001709px);
    img {
        width: 35px;
        height: 35px;
    }
}

.item-icon {
    width: 47.5px;
    height: auto;
    border-radius: 3.373px;
    backdrop-filter: blur(4.487180233001709px);
    img {
        max-width: 100%;
        height: auto;
    }
}

.inventory {
    display: flex;
    align-items: flex-start;
    gap: 8.988px;
    width: 100%;
    padding-bottom: 50px;
    flex-wrap: wrap;
    .inventory-item {
        display: flex;
        width: 224.5px;
        height: 407px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8.572px;
        border: 0.857px solid rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(4.285879611968994px);
        .inventory-item-loading {
            display: flex;
            justify-content: center;
            align-items: center;
            animation: spin 1s linear infinite;
            width: 15px;
            height: 15px;
            margin-right: 29px;
            margin: auto; /* Add this line */
        }
        .inventory-item-top {
            width: 224.5px;
            height: 171px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                height: 120px;
            }
            .inventory-item-top-info {
                position: absolute;
                left: 15px;
                top: 15px;
                .red-background {
                    border: 1px solid #ef3737;
                    border-radius: 5px;
                    background-color: rgba(239, 55, 55, 0.15);
                    padding: 4px 8px 4px 8px;
                    color: #ef3737;
                    font-family: Poppins;
                    font-size: 10.286px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    flex-direction: row;
                }
                .green-background {
                    border: 1px solid #0ecb43;
                    border-radius: 5px;
                    background-color: rgba(14, 203, 67, 0.15);
                    padding: 4px 8px 4px 8px;
                    color: #0ecb43;
                    font-family: Poppins;
                    font-size: 10.286px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    flex-direction: row;
                }
            }
        }
        .inventory-item-bottom {
            width: 224.5px;
            height: 255px;
            flex-shrink: 0;
            border-radius: 0px 0px 8px 8px;
            background: rgba(255, 255, 255, 0.04);
            backdrop-filter: blur(4.285879611968994px);
            display: flex;
            align-items: center;
            justify-content: center;
            .inventory-item-bottom-inner {
                align-items: center;
                justify-content: center;
                display: flex;
                width: 206px;
                flex-direction: column;
                gap: 13.715px;
                .inventory-item-bottom-name {
                    width: 190px;
                    text-align: left;
                    height: 30px;
                    color: #fff;
                    font-family: Poppins;
                    font-size: 13.715px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20.572px; /* 150% */
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .inventory-item-condition {
                    color: var(--Yellow, #d2e214);
                    font-family: Poppins;
                    font-size: 10.286px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 10.286px; /* 100% */
                    letter-spacing: 0.823px;
                    text-transform: uppercase;
                }
                .inventory-item-bottom-values {
                    display: flex !important;
                    align-items: flex-start;
                    gap: 22.287px;
                    .inventory-item-bottom-value {
                        display: flex !important;
                        flex-direction: column !important;
                        align-items: flex-start;
                        width: 80px;
                        .inventory-item-bottom-value-title {
                            color: #fff;
                            font-family: Poppins;
                            font-size: 10.286px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: 13.715px; /* 133.333% */
                            letter-spacing: 0.103px;
                        }
                        .inventory-item-bottom-value-number {
                            color: #fff;

                            font-family: Poppins;
                            font-size: 17.144px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 27.43px; /* 160% */
                            letter-spacing: 0.171px;
                        }
                        .green {
                            color: var(--Yellow, #d2e214);
                        }
                    }
                }
                .inventory-item-bottom-listed {
                    color: var(--Sw-Grey, #a4a4a4);
                    font-family: Poppins;
                    font-size: 10.286px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 10.286px; /* 100% */
                    letter-spacing: 0.823px;
                    text-transform: uppercase;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 200px;
                    gap: 5px;
                    .inventory-item-bottom-listed-sites {
                        height: 49px;
                        display: flex !important;
                        align-items: flex-start;
                        flex-direction: row !important;
                        flex-wrap: wrap !important;
                        gap: 4px;
                        .inventory-item-bottom-listed-site {
                            display: flex !important;
                            flex-direction: row;
                            padding: 3.429px 5.143px;
                            justify-content: center;
                            align-items: center;
                            gap: 4.286px;
                            border-radius: 3.429px;
                            background: rgba(255, 255, 255, 0.04);
                            backdrop-filter: blur(4.285879611968994px);
                            color: #7f7f7f;
                            font-family: Poppins;
                            font-size: 10.286px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            img.lowest {
                                border: 1px solid var(--Yellow, #d2e214);
                            }
                            img {
                                width: 14.341px;
                                height: 14.239px;
                                flex-shrink: 0;
                                border-radius: 4px;
                                border: 1px solid rgba(255, 255, 255, 0.1);
                            }
                        }
                    }
                }
            }
        }
    }
}
::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-thumb {
    background: #d2e214;
    border-radius: 3px;
}

::-webkit-scrollbar-track:hover {
    background: none;
}

/* Style the scrollbar thumb when hovering over it */
::-webkit-scrollbar-thumb:hover {
    background: #d2e214;
}

body {
    padding-right: 6px;
    overflow-y: scroll;
}

.item-header {
    display: flex;
    align-items: flex-start;
    gap: 123px;

    .item-header-left {
        display: flex;
        flex-direction: column;
        gap: 34px;
        height: inherit;
        .item-header-image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 462.602px;
            flex-shrink: 0;
            border-radius: 16.119px;
            border: 1.612px solid rgba(255, 255, 255, 0.1);
            backdrop-filter: blur(8.059579849243164px);
            img {
                display: flex;
                height: 250px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
            }
            .item-header-image-top-info {
                position: absolute;
                left: 25px;
                top: 25px;
                .red-background {
                    border: 1px solid #ef3737;
                    border-radius: 5px;
                    background-color: rgba(239, 55, 55, 0.15);
                    padding: 4px 8px 4px 8px;
                    color: #ef3737;
                    font-family: Poppins;
                    font-size: 10.286px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    flex-direction: row;
                }
                .green-background {
                    border: 1px solid #0ecb43;
                    border-radius: 5px;
                    background-color: rgba(14, 203, 67, 0.15);
                    padding: 4px 8px 4px 8px;
                    color: #0ecb43;
                    font-family: Poppins;
                    font-size: 10.286px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    flex-direction: row;
                }
            }
        }
    }
    .item-header-name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 14px;
        color: #fff;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .item-header-condition {
            color: var(--Yellow, #d2e214);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 10.286px; /* 73.472% */
            letter-spacing: 1.12px;
            text-transform: uppercase;
        }
    }
    .item-header-divider {
        width: 678px;
        height: 1.025px;
        flex-shrink: 0;
        background: var(--Sw-Dark-Grey, #202023);
    }

    .item-header-stats {
        display: flex;
        align-items: flex-start;
        gap: 35px;
        flex-direction: row;
        .item-header-stat {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .item-header-stat-title {
                color: #a4a4a4;
                font-family: Poppins;
                font-size: 11.055px;
                font-style: normal;
                font-weight: 300;
                line-height: 14.739px; /* 133.333% */
                letter-spacing: 0.111px;
            }
            .item-header-stat-number {
                color: #fff;
                font-family: Poppins;
                font-size: 18.424px;
                font-style: normal;
                font-weight: 400;
                line-height: 29.479px; /* 160% */
                letter-spacing: 0.184px;
            }
        }
    }
    .item-header-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 28px;
        .item-header-listed {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 18px;
            .item-header-listed-header {
                color: var(--Sw-Grey, #a4a4a4);
                font-family: Poppins;
                font-size: 14.035px;
                font-style: normal;
                font-weight: 400;
                line-height: 14.035px; /* 100% */
                letter-spacing: 1.123px;
                text-transform: uppercase;
            }

            .item-header-listed-site {
                width: 678px;
                height: 79px;
                border-radius: 8.396px;
                background: rgba(255, 255, 255, 0.04);
                backdrop-filter: blur(10.495254516601562px);
                display: flex;
                flex-direction: row;
                .item-header-listed-site-left {
                    padding-left: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 18px;
                    flex-direction: row;
                }
                .item-header-listed-site-right {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 18px;
                    flex-direction: row;
                    flex: 1 1;
                    padding-right: 12px;
                }
                .inventory-item-bottom-listed-price {
                    border-radius: 6px;
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    background: rgba(255, 255, 255, 0.04);
                    backdrop-filter: blur(5px);
                    display: flex;
                    height: 41px;
                    padding: 5px 12px;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: #fff;
                    text-align: center;
                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 27.43px; /* 152.387% */
                    letter-spacing: 0.18px;
                }
                .item-header-listed-site-image {
                    width: 39.88px;
                    height: 39.766px;
                    border-radius: 6px;
                }
                .item-header-listed-site-name {
                    color: #fff;
                    font-family: Poppins;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }
}

a {
    text-decoration: none;
}
